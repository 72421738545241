import './main.scss';
import { useEffect, useState, useRef, useCallback } from "react";
import { googleLogout } from '@react-oauth/google';
import { Link } from "react-router-dom"
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { 
  saveVideo, getSourceLanguages, getTargetLanguages, saveTargetLanguages, 
  trimVideo, getAllTargetLanguages, updateNumPlay, shareVideo, getVidClips
} from "../../apis/video";
import { getUserInfo } from "../../apis/user"
import { FaPhotoVideo, FaCaretDown, FaMale, FaFemale, FaCut, FaYoutube, FaFacebook, FaTiktok, FaDropbox } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { IoSearch, IoCloudUploadOutline } from "react-icons/io5";
import { AiOutlineLoading3Quarters, AiOutlineLogout, AiFillInstagram } from "react-icons/ai";
import { IoIosAddCircleOutline, IoIosArrowDown, IoIosArrowUp, IoIosClose } from "react-icons/io";
import { MdOutlineChangeCircle, MdEdit, MdOndemandVideo } from "react-icons/md";
import { CiSettings, CiSaveDown1 } from "react-icons/ci";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GrOnedrive, GrInstagram } from "react-icons/gr";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

const route = process.env.REACT_APP_URL
const voices = ["Male", "Female"]
const translatingSteps = [
  "Cut & Combine",
  "Detect original language",
  "Transcripting",
  "Translating",
  "TTS & Syncing",
  "Loading video..."
]
const prod = process.env.NODE_ENV == "production"
const routeUrl = process.env.REACT_APP_URL
const saveUrl = process.env.REACT_APP_SAVE_URL

export default function Main() {
  const file = useRef(null)
  const [link, setLink] = useState(prod === true ? "" : "https://www.youtube.com/watch?v=qMprZlnVJ0I")

  const [userId, setUserid] = useState(null)
  const [username, setUsername] = useState('')
  const [uploadedVideo, setUploadedvideo] = useState({ name: "", loading: false })
  const [trim, setTrim] = useState({ begin: 0, end: 0, loading: false, errorMsg: "" })
  const [trimming, setTrimming] = useState(false)
  const [translating, setTranslating] = useState(false)
  const [translateProgressing, setTranslateprogressing] = useState({ loaded: 0, total: 0 })
  const [detectedSourcelang, setDetectedsourcelang] = useState('')
  const [showFaketranslate, setShowfaketranslate] = useState({ show: false, fakeTranslateSeconds: 0 })
  const [showFeedback, setShowfeedback] = useState(false)

  // show playlist on vidclips exporting
  const [showPlaylist, setShowplaylist] = useState(false)
  const [playlist, setPlaylist] = useState([])
  const [finalPlaylist, setFinalplaylist] = useState([])
  const [playlistIndex, setPlaylistindex] = useState(0)
  const [playlistLoading, setPlaylistloading] = useState(false)
  const [playlistVideoloading, setPlaylistvideoloading] = useState(false)
  const [numClips, setNumclips] = useState(0)

  const [translatedVideo, setTranslatedvideo] = useState('')
  const [downloadLink, setDownloadlink] = useState('')
  const [translatingIndex, setTranslatingindex] = useState(0)
  const [translatedVideoid, setTranslatedvideoid] = useState(null)
  const [savingVideo, setSavingvideo] = useState(false)
  const [gettingSocialmediavideo, setGettingsocialmediavideo] = useState(false)
  const [videoDuration, setVideoduration] = useState(0.0)
  const [videoCutted, setVideocutted] = useState(false)
  const [copiedLink, setCopiedlink] = useState(false)
  const [showUndone, setShowundone] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    let file = acceptedFiles
    let reader = new FileReader()

    if (file && file[0]) {
      reader.onload = data => {
        saveTheVideo(reader.result)
      }

      reader.readAsDataURL(file[0])
    }
  }, [userId])
  const { getRootProps, rejectedFiles, acceptedFiles, getInputProps, inputRef, isDragActive } = useDropzone({ onDrop })

  // video's original language, source language
  const [sourceLangs, setSourcelangs] = useState([])
  const [sourceLangslist, setSourcelangslist] = useState([])
  const [openSlang, setOpenslang] = useState(false)
  const [selectedSLang, setSelectedslang] = useState({ code: '', name: '' })

  // settings for video audio language translation, target language
  const [targetLangs, setTargetlangs] = useState([])
  const [targetLangslist, setTargetlangslist] = useState([])
  const [openTlang, setOpentlang] = useState(false)
  const [selectedTLang, setSelectedtlang] = useState({ code: '', name: '' })
  const [openVoice, setOpenvoice] = useState(false)
  const [selectedVoice, setSelectedvoice] = useState('')
  const [splitMe, setSplitme] = useState("N")

  // edit target languages
  const [editTlang, setEdittlang] = useState({ show: false, openLang: false, selectedLangs: [], allLangs: [] })

  const [errorMsg, setErrormsg] = useState('')

  const getTheSourceLanguages = () => {
    getSourceLanguages()
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSourcelangs(res.sLangs)
          setSourcelangslist(res.sLangs)
          setSelectedslang(res.defaultLang)
        }
      })
  }
  const getTheTargetLanguages = () => {
    const data = { userId }

    getTargetLanguages(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        
        throw res
      })
      .then((res) => {
        if (res) {
          setTargetlangs(res.tLangs)
          setTargetlangslist(res.tLangs)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const browseVideo = e => {
    let file = e.target.files
    let reader = new FileReader()

    if (file && file[0]) {
      reader.onload = data => {
        saveTheVideo(reader.result)
      }

      reader.readAsDataURL(file[0])
    }
  }
  const saveTheVideo = uri => {
    setVideocutted(false)
    setSavingvideo(true)
    setUploadedvideo({ ...uploadedVideo, name: '', loading: true })
    setTranslatedvideoid(null)

    const data = { userId, video: uri }

    saveVideo(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setUploadedvideo({ ...uploadedVideo, name: res.uploaded, loading: false })
          setTrim({ ...trim, errorMsg: "" })
          setVideoduration(res.duration)
          setTrim({ ...trim, begin: 0, end: res.duration })
          setVideocutted(res.cutDuration)
          setSavingvideo(false)
          setErrormsg("")
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSavingvideo(false)
          setErrormsg("File not supported. Make sure you browse a video file")
        }
      })
  }
  const getTheSocialMediaVideo = () => {
    setVideocutted(false)
    setGettingsocialmediavideo(true)
    setUploadedvideo({ ...uploadedVideo, name: '', loading: true })
    setTranslatedvideo('')
    setTranslatedvideoid(null)

    const data = { userId, link }

    axios.post(route + "/flask/video/get_socialmedia_video", data, {
      headers: { "Content-Type": "application/json" },
      onUploadProgress: ({ loaded, total }) => {
        console.log(loaded, total)
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setUploadedvideo({ ...uploadedVideo, name: res.uploaded, loading: false })
          setVideoduration(res.duration)
          setTrim({ ...trim, begin: 0, end: res.duration })
          setVideocutted(res.cutDuration)
          setGettingsocialmediavideo(false)
          setErrormsg("")
        }
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setGettingsocialmediavideo(false)
          setErrormsg("Link is invalid. Make sure you entered a proper link")
        }
      })
  }
  const searchLanguages = (lang, type) => {
    const searches = []

    if (type === "source") {
      if (lang) {
        sourceLangs.forEach(function (info) {
          if (info.name.substr(0, lang.length).toLowerCase() === lang.toLowerCase()) {
            searches.push(info)
          }
        })

        setSourcelangslist(searches)
      } else {
        setSourcelangslist(sourceLangs)
      }
    } else {
      if (lang) {
        targetLangs.forEach(function (info) {
          if (info.name.substr(0, lang.length).toLowerCase() === lang.toLowerCase()) {
            searches.push(info)
          }
        })

        setTargetlangslist(searches)
      } else {
        setTargetlangslist(targetLangs)
      }
    }
  }
  const searchTheTargetLanguage = (lang, type) => {
    const searches = []

    if (lang) {
      editTlang.allLangs.forEach(function (info) {
        if (info.name.substr(0, lang.length).toLowerCase() === lang.toLowerCase()) {
          searches.push(info)
        }
      })
      
      setEdittlang({ ...editTlang, allLangs: searches })
    } else {
      setEdittlang({ ...editTlang, allLangs: [] })
    }
  }
  const getTheUserInfo = () => {
    getUserInfo({ userId })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.info.verified && res.info.targetLangs.length > 0) {
            setUsername(res.info.username)
          } else {
            window.location = "/setup"
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const editTheTargetLanguages = () => {
    const data = { userId }

    getAllTargetLanguages()
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const allLangs = res.tLangs

          getUserInfo(data)
            .then((res) => {
              if (res.ok) {
                return res.json()
              }

              throw res
            })
            .then((res) => {
              if (res) {
                setEdittlang({ ...editTlang, show: true, selectedLangs: res.info.targetLangs, allLangs })
              }
            })
        }
      })
  }
  const saveTheTargetLanguages = () => {
    const { selectedLangs } = editTlang
    const data = { userId, selectedLangs: JSON.stringify(selectedLangs) }

    saveTargetLanguages(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setEdittlang({ ...editTlang, show: false })
          getTheTargetLanguages()
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }

  const startTranslate = () => {
    const { begin, end } = trim

    if (begin > 0 || end < videoDuration) { // trim video first
      trimTheVideo()
    } else {
      theCutCombine()
    }
  }
  const trimTheVideo = () => {
    setTranslating(true)
    setTrimming(true)

    const { begin, end } = trim

    if (end - begin > 0) {
      const trimLength = end - begin

      if (trimLength >= 10 && trimLength < videoDuration) {
        setUploadedvideo({ ...uploadedVideo, loading: true })
        setTrim({ ...trim, loading: true, errorMsg: "" })
        
        const data = {
          userId,
          cutStart: begin, 
          cutEnd: end
        }

        trimVideo(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setUploadedvideo({ ...uploadedVideo, name: res.videoName, loading: false })
              setTrim({ ...trim, begin: 0, end: 0, loading: false })
              setTrimming(false)
              setVideoduration(trimLength)
              theCutCombine()
            }
          })
          .catch((err) => {
            if (err.status == 400) {

            }
          })
      } else {
        setTrim({ 
          ...trim, 
          errorMsg: trimLength < 10 ? "Trim length has to be at least 10 seconds" : "Cannot trim video longer than the video's duration"
        })
      }
    } else {
      setTrim({ ...trim, errorMsg: "You haven't set a begin or end trim value" })
    }
  }

  // cut & combine
  const theCutCombine = async () => {
    if (selectedTLang.code && selectedVoice) {
      setTranslatedvideo('')

      const data = {
        userId,
        "videoFile": uploadedVideo.name,
        "sLang": selectedSLang.code,
        "tLang": selectedTLang.code.toUpperCase(),
        "VOgender": selectedVoice.substr(0, 1).toUpperCase(),
        "splitMe": splitMe
      }

      setTranslatingindex(0)
      setTranslating(true)
      setErrormsg('')

      axios.post(routeUrl + "/flask/video/cut_combine", data, {
        onUploadProgress: ({ loaded, total }) => {
          setTranslateprogressing({ ...translateProgressing, loaded, total })
        }
      })
        .then((res) => {
          if (res.status == 200) {
            return res.data
          }

          throw res
        })
        .then((res) => {
          if (res) {
            detectTheSourceLanguage()
          }
        })
        .catch((err) => {
          if (err.response.status == 400) {
            switch (err.response.data.status) {
              case "unselectedSourceLang":
                setTranslating(false)
                setTranslatingindex(-1)
                setTranslatedvideo('')
                setErrormsg("Please select the source language")

                break;
              case "unselectedTargetLang":
                setTranslating(false)
                setTranslatingindex(-1)
                setTranslatedvideo('')
                setErrormsg("Please select a target language for translation")

                break;
              case "unselectedVoice":
                setTranslating(false)
                setTranslatingindex(-1)
                setTranslatedvideo('')
                setErrormsg("Please select the voice for the translation")

                break;
              case "blacklisted":
                const { fakeTranslateSeconds, fakeInfo } = err.response.data

                setShowfaketranslate({ ...showFaketranslate, show: true, fakeTranslateSeconds })
                
                setTimeout(function () {
                  setTranslatingindex(-1)
                  setTranslating(false)

                  setPlaylistindex(0)
                  setShowplaylist(true)
                  setTranslateprogressing(false)
        
                  setPlaylist([fakeInfo["translatedVideo"]])

                  setTranslatedvideoid(null)
                  setUploadedvideo({ ...uploadedVideo, name: fakeInfo["uploadedVideo"], loading: false })
                  setTrim({ ...trim, errorMsg: '' })
                  setErrormsg('')
                }, 
                  err.response.data.fakeTranslateSeconds
                )

                break;
            }
          } else {
            setErrormsg('Error cut & combining')
          }
        })
    } else {
      if (!selectedTLang.code) {
        setErrormsg("Please select a target language to translate to")
      } else {
        setErrormsg("Please select a voice for the audio")
      }
    }
  }

  // detect source language
  const detectTheSourceLanguage = () => {
    setTranslatingindex(1)

    const data = { userId }

    axios.post(routeUrl + "/flask/video/detect_source_language", data, {
      onUploadProgress: ({ loaded, total }) => {
        setTranslateprogressing({ ...translateProgressing, loaded, total })
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.sourceLang) {
            setDetectedsourcelang(res.sourceLang)
          }

          theTranscripting()
        }
      })
      .catch((err) => {
        setTranslating(false)
        setTranslatingindex(-1)
        setTranslatedvideo('')

        setErrormsg("Failed detecting source language")
      })
  }

  // transcripting
  const theTranscripting = () => {
    setTranslatingindex(2)

    const data = { userId }

    axios.post(routeUrl + "/flask/video/transcripting", data, {
      onUploadProgress: ({ loaded, total }) => {
        setTranslateprogressing({ ...translateProgressing, loaded, total })
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          theTranslating()
        }
      })
      .catch((err) => {
        setTranslating(false)
        setTranslatingindex(-1)
        setTranslatedvideo('')

        if (err.response.status == 400) {
          switch (err.response.data.status) {
            case "unmatchedSourceLanguage":
              setErrormsg("Source language doesn't match the language in video")

              break;
          }
        } else {
          setErrormsg("Failed translating")
        }
      })
  }

  // translating
  const theTranslating = () => {
    setTranslatingindex(3)

    const data = { userId }

    axios.post(routeUrl + "/flask/video/translating", data, {
      onUploadProgress: ({ loaded, total }) => {
        setTranslateprogressing({ ...translateProgressing, loaded, total })
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          theDubbingPreparing()
        }
      })
      .catch((err) => {
        setTranslating(false)
        setTranslatingindex(-1)
        setTranslatedvideo('')

        setErrormsg("Failed translating")
      })
  }

  // dubbing preparing
  const theDubbingPreparing = () => {
    setTranslatingindex(4)

    const data = { userId }

    axios.post(routeUrl + "/flask/video/dubbing_preparing", data, {
      onUploadProgress: ({ loaded, total }) => {
        setTranslateprogressing({ ...translateProgressing, loaded, total })
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          theTTSAndSync(true)
        }
      })
      .catch((err) => {
        setTranslating(false)
        setTranslatingindex(-1)
        setTranslatedvideo('')

        setErrormsg("Failed dubbing")
      })
  }

  // tts & sync
  const theTTSAndSync = start => {
    setTranslatingindex(5)

    const data = { userId }

    axios.post(routeUrl + "/flask/video/tts_and_sync", data, {
      onUploadProgress: ({ loaded, total }) => {
        setTranslateprogressing({ ...translateProgressing, loaded, total })
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { genClips, finalClips, numClips } = res

          if (start) {
            if (!showPlaylist) {
              setPlaylistindex(0)
              setShowplaylist(true)
              setTranslateprogressing(false)
              setNumclips(numClips)
            }
          }

          if (genClips.length > 0) {
            setPlaylist(prev => {
              return [...prev, ...genClips]
            })
            setFinalplaylist(prev => {
              return [...prev, ...finalClips]
            })
          }

          if (res.done) {
            setTimeout(function () {
              theExporting()
            }, 1000)
          } else {
            theTTSAndSync(false)
          }
        }
      })
      .catch((err) => {
        setTranslating(false)
        setTranslatingindex(-1)
        setTranslatedvideo('')

        setErrormsg("Failed tts generating")
      })
  }
  const theExporting = () => {
    const data = { userId }

    axios.post(routeUrl + "/flask/video/exporting", data, {
      onUploadProgress: ({ loaded, total }) => {
        setTranslateprogressing({ ...translateProgressing, loaded, total })
      }
    })
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }

        throw res
      })
      .then((res) => {
        if (res) {          
          setTranslatingindex(-1)
          setTranslating(false)
          setUploadedvideo({ ...uploadedVideo, name: res.uploadedVideo, loading: false })
          setTrim({ ...trim, errorMsg: '' })
          setErrormsg('')
          setTranslatedvideoid(res.videoId)
          setShowfeedback(true)
        }
      })
      .catch((err) => {
        setTranslating(false)
        setTranslatingindex(-1)
        setTranslatedvideo('')

        setErrormsg("Failed exporting")
      })
  }

  const updateTheNumPlay = () => {
    const data = { videoId: translatedVideoid, type: "after_translated" }

    updateNumPlay(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {

        }
      })
      .catch((err) => {

      })
  }
  const shareTheVideo = () => {
    const data = { videoId: translatedVideoid, type: "translated" }

    shareVideo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          let value = document.getElementById("share-video-header-value").innerHTML

          if (value) {
            navigator.clipboard.writeText(value)

            setCopiedlink(true)

            setTimeout(function () {
              setCopiedlink(false)
            }, 500)
          }
        }
      })
      .catch((err) => {
        if (err.status == 400) {

        }
      })
  }

  useEffect(() => {
    const id = localStorage.getItem("id")

    setUserid(id)
  }, [])

  useEffect(() => {
    if (userId) {
      getTheSourceLanguages()
      getTheTargetLanguages()
      getTheUserInfo()
    }
  }, [userId])

  useEffect(() => {
    if (downloadLink) {
      setTimeout(function () {
        document.getElementById("download").click()
      }, 1000)
    }
  }, [downloadLink])

  useEffect(() => {
    if (showPlaylist) {
      if (playlistIndex > 0) {
        document.getElementById("video-" + playlistIndex).play() // play video after index updates
      }
    }
  }, [playlistIndex])

  return (
    <div id="main" onContextMenu={e => {
      if (process.env.NODE_ENV != "development") {
        if (translatedVideo) {
          e.preventDefault()
        }
      }
    }} onClick={() => {
      // cancel all dropdown

      if (openSlang) {
        getTheSourceLanguages()
      }

      if (openTlang) {
        getTheTargetLanguages()
      }

      setOpenslang(false)
      setOpentlang(false)
      setOpenvoice(false)
      setEdittlang({ ...editTlang, show: false })
    }}>
      <Header/>

      <div id="user-actions-container">
        <div id="user-actions-column">
          <div id="user-actions">
            <div className="user-action" onClick={() => {
              localStorage.clear()

              googleLogout();

              window.location = "/login"
            }}>
              <div className="user-action-header">Log-Out</div>
              <div className="user-action-icon"><AiOutlineLogout style={{ height: '100%', width: '100%' }}/></div>
            </div>
          </div>
        </div>
      </div>

      <div id="body">
        <div id="top-section">
          {(!gettingSocialmediavideo && !savingVideo && !uploadedVideo.name) ? 
            <>
              <div id="intro">
                <div>
                  <div id="intro-header">Welcome {username} to Videodub AI</div>

                  <div id="mini-headers">
                    <div className="mini-header">
                      You can start by copying and pasting a video link, 
                      clicking the box to upload a video, or simply dragging
                      and dropping a video onto the box
                    </div>
                  </div>
                </div>
              </div>
              <div id="inputs">
                <div id="inputs-container">
                  <div id="socialmedia-icons">
                    <div id="icons-row">
                      <div className="socialmedia-icon"><FaYoutube style={{ color: 'red', height: '100%', width: '100%' }}/></div>
                      <div className="socialmedia-icon"><FaFacebook style={{ color: '#0083FF', height: '100%', width: '100%' }}/></div>
                      <div className="socialmedia-icon"><GrInstagram style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                      <div className="socialmedia-icon"><FaTiktok style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                      <div className="socialmedia-icon"><FaSquareXTwitter style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                      <div className="socialmedia-icon"><FaDropbox style={{ color: '#0074FF', height: '100%', width: '100%' }}/></div>
                      <div className="socialmedia-icon"><GrOnedrive style={{ color: '#00AAFF', height: '100%', width: '100%' }}/></div>
                    </div>
                  </div>
                  <div id="enter-youtube">
                    <input 
                      id="youtube-input" placeholder="Paste a link here" 
                      maxLength="100" onChange={e => setLink(e.target.value)}
                      value={link}
                    />
                    <div id="youtube-search" style={{ backgroundColor: link ? 'black' : 'white' }} onClick={() => getTheSocialMediaVideo()}>
                      <IoSearch style={{ color: link ? 'white' : 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                  <div className="errormsg" style={{ marginTop: 10 }}>{errorMsg}</div>
                  <input id="file-input" ref={file} type="file" onChange={browseVideo} style={{ display: 'none' }}/>

                  {!uploadedVideo.name && (
                    <div id="dragdrop-video" {...getRootProps()} onDropCapture={() => {
                      inputRef.current.value = ""
                      
                      setUploadedvideo('')
                      setTranslatedvideo('')
                    }}>
                      <div id="dragdrop-video-row">
                        <div className="column">
                          <div id="dragdrop-video-header">
                            Click to upload
                            <br/>or<br/>
                            Drag & Drop a video here
                            <br/><br/>
                            (accepted: mov, mp4, webm)
                          </div>
                        </div>
                      </div>
                      <input ref={file} {...getInputProps()} accept="video/mov,video/mp4,video/webm"/>
                    </div>
                  )}
                </div>
              </div>
            </>
            :
            <>
              {gettingSocialmediavideo && (
                <div id="getting-video-loading">
                  <div id="video-loading-header">Getting the video by link. Please wait a moment.....</div>
                  <div id="loading-icon" style={{ height: 28, width: 28 }}>
                    <AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              {savingVideo && (
                <div id="getting-video-loading">
                  <div id="video-loading-header">Getting video. Please wait a moment.....</div>
                  <div id="loading-icon" style={{ height: 28, width: 28 }}>
                    <AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              {uploadedVideo.name && (
                <div id="main-container">
                  {showPlaylist && (
                    <div id="playlist">
                      <div id="container">
                        <div id="translated-header">Enjoy your translated video</div>
                        <div id="holder">
                          {!playlistLoading ? 
                            playlist.map((video, index) => (
                              <video 
                                preload="auto"
                                id={"video-" + index}
                                style={{ display: playlistIndex == index ? 'block' : 'none' }}
                                controls={false}
                                onClick={() => {
                                  const video = document.getElementById("video-" + index)
                                  
                                  if (video.paused) {
                                    video.play()
                                  } else {
                                    video.pause()
                                  }
                                }}
                                autoPlay={playlistIndex == index}
                                onPlay={() => updateTheNumPlay()} 
                                onEnded={() => {
                                  if (playlistIndex == playlist.length - 1) {
                                    if (playlist.length == numClips + 1) {
                                      setPlaylistindex(0)
                                    }
                                  } else {
                                    setPlaylistindex(playlistIndex + 1)
                                  }
                                }}
                                controlsList="nodownload" onContextMenu={e => e.preventDefault()}
                              >
                                <source src={routeUrl + "/flask/" + video} type="video/mp4"/>
                                <source src={routeUrl + "/flask/" + finalPlaylist[index]} type="video/mp4"/>
                              </video>
                            ))
                            :
                            <div id="loading-icon-holder">
                              <div id="loading-icon" style={{ height: 50, width: 50 }}></div>
                            </div>
                          }
                        </div>

                        {showFeedback && <div id="feedback-header">Are you satified with the video ? We would love your feedback <a href="https://forms.office.com/r/Cwtvi5McA5" target="_blank">here</a></div>}

                        <div id="translated-actions" style={{ opacity: translatedVideoid ? 1 : 0.3 }}>
                          <div className={"translated-action" + (translatedVideoid ? "" : "-disabled")} onClick={() => {
                            if (translatedVideoid) {
                              setUploadedvideo('')
                              setTranslatedvideo('')
                              setTranslating(false)
                              setShowplaylist(false)
                              setPlaylist([])
                              setFinalplaylist([])
                              setShowfeedback(false)
                              setDetectedsourcelang('')
                              setTrim({ ...trim, errorMsg: '' })
                              setShowfaketranslate({ ...showFaketranslate, show: false })
                              setTranslatedvideoid(null)
                              setErrormsg('')
                            } else {
                              setShowundone(true)

                              setTimeout(function () {
                                setShowundone(false)
                              }, 2000)
                            }
                          }}>
                            <div className="column"><div className="translated-action-header">Get different video</div></div>
                            <div className="column">
                              <div className="translated-action-icon">
                                <IoCloudUploadOutline style={{ height: '100%', width: '100%' }}/>
                              </div>
                            </div>
                          </div>
                          <div className={"translated-action" + (translatedVideoid ? "" : "-disabled")} onClick={() => {
                            if (translatedVideoid) {
                              setTranslatedvideo('')
                              setTranslating(false)
                              setShowplaylist(false)
                              setPlaylist([])
                              setFinalplaylist([])
                              setDetectedsourcelang('')
                              setShowfeedback(false)
                              setTrim({ ...trim, errorMsg: '' })
                              setShowfaketranslate({ ...showFaketranslate, show: false })
                              setTranslatedvideoid(null)
                              setErrormsg('')
                            } else {
                              setShowundone(true)

                              setTimeout(function () {
                                setShowundone(false)
                              }, 2000)
                            }
                          }}>
                            <div className="column"><div className="translated-action-header">Change settings</div></div>
                            <div className="column">
                              <div className="translated-action-icon">
                                <CiSettings style={{ height: '100%', width: '100%' }}/>
                              </div>
                            </div>
                          </div>
                          {translatedVideoid != "null" && (
                            <div id="share-video-container">
                              <div id="share-video-header">
                                <div id="share-video-header-value">{process.env.REACT_APP_SHARE_URL}/{translatedVideoid}</div>
                              </div>
                              <div id={"share-video-button" + (translatedVideoid ? "" : "-disabled")} onClick={() => {
                                if (translatedVideoid) {
                                  shareTheVideo()
                                } else {
                                  setShowundone(true)

                                  setTimeout(function () {
                                    setShowundone(false)
                                  }, 2000)
                                }
                              }}>
                                Share
                                {copiedLink && <><br/>(copied)</>}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {!showPlaylist && (
                    <div id="uploaded-video-container">
                      <div id="settings" style={{ opacity: (translating || trim.loading) ? 0.5 : 1 }}>
                        <div id="settings-header">Select translate settings</div>

                        <div id="settings-container">
                          <div id="selections">
                            <div className="selection">
                              <div className="selection-header">Select the video language</div>

                              <div className="selection-container">
                                <div className={"selection-button" + (translating ? "-disabled" : "")} onClick={(e) => {
                                  if (!translating && !trim.loading) {
                                    setOpenslang(!openSlang)
                                  }

                                  e.stopPropagation()
                                }}>
                                  <div className="column"><div className="selection-header-header">{selectedSLang.name ? selectedSLang.name : "Select the language"}</div></div>
                                  <div className="selection-arrow"><FaCaretDown style={{ height: '100%', width: '100%' }}/></div>
                                </div>

                                {openSlang === true && (
                                  <div className="selection-items" onClick={(e) => e.stopPropagation()}>
                                    <input id="search-input" placeholder="Search language" maxLength="100" onChange={(e) => searchLanguages(e.target.value, "source")}/>

                                    <div id="selection-items-list">
                                      {sourceLangslist.map((info, index) => (
                                        <div className="selection-item" key={"source-lang-" + index} onClick={() => {
                                          setSelectedslang(info)
                                          setOpenslang(false)
                                          setErrormsg("")
                                        }}>
                                          <div className="column"><div className="selection-item-point"/></div>
                                          <div className="column"><div className="selection-item-header">{info.name}</div></div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="selection">
                              <div className="selection-header">Select the language to translate into</div>

                              <div className="selection-container">
                                <div className="row">
                                  <div className={"selection-button" + (translating ? "-disabled" : "")} onClick={(e) => {
                                    if (!translating && !trim.loading) {
                                      setOpentlang(!openTlang)
                                    }

                                    e.stopPropagation()
                                  }}>
                                    <div className="column"><div className="selection-header-header">{selectedTLang.name ? selectedTLang.name : "Select the language"}</div></div>
                                    <div className="selection-arrow"><FaCaretDown style={{ height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="column"><div className={"selection-edit" + (translating ? "-disabled" : "")} onClick={() => {
                                    if (!translating && !trim.loading) {
                                      editTheTargetLanguages()
                                    }
                                  }}><MdEdit style={{ height: '100%', width: '100%' }}/></div></div>
                                </div>

                                {openTlang === true && (
                                  <div className="selection-items" onClick={(e) => e.stopPropagation()}>
                                    <input id="search-input" placeholder="Search language" maxLength="100" onChange={(e) => searchLanguages(e.target.value, "target")}/>

                                    <div id="selection-items-list">
                                      {targetLangslist.map((info, index) => (
                                        <div className="selection-item" key={"target-lang-" + index} onClick={() => {
                                          setSelectedtlang(info)
                                          setOpentlang(false)
                                          setErrormsg("")
                                        }}>
                                          <div className="column"><div className="selection-item-point"/></div>
                                          <div className="column"><div className="selection-item-header">{info.name}</div></div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="selection">
                              <div className="selection-header">Select the voice gender</div>

                              <div className="selection-container">
                                <div className={"selection-button" + (translating ? "-disabled" : "")} onClick={(e) => {
                                  if (!translating) {
                                    setOpenvoice(!openVoice)
                                  }

                                  e.stopPropagation()
                                }}>
                                  <div className="column"><div className="selection-header-header">{selectedVoice ? selectedVoice : "Select the voice"}</div></div>
                                  <div className="selection-arrow"><FaCaretDown style={{ height: '100%', width: '100%' }}/></div>
                                </div>

                                {openVoice === true && (
                                  <div className="selection-items">
                                    <div id="selection-items-list">
                                      {voices.map((info, index) => (
                                        <div className="selection-item" key={"selection-item-" + index} onClick={() => {
                                          setSelectedvoice(info)
                                          setOpenvoice(false)
                                          setErrormsg("")
                                        }}>
                                          <div className="column">
                                            <div className="selection-item-icon">
                                              {info === "Male" ? 
                                                <FaMale style={{ height: '100%', width: '100%' }}/>
                                                :
                                                <FaFemale style={{ height: '100%', width: '100%' }}/>
                                              }
                                            </div>
                                          </div>
                                          <div className="column"><div className="selection-item-header">{info}</div></div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="selection">
                              <div className="selection-header">Is there background music in your video?</div>

                              <div className="selection-radios">
                                <div className="selection-radio">
                                  <label for="bgmusic">Yes</label>
                                  <input type="radio" name="bgmusic" value="Yes" onClick={(e) => setSplitme(e.target.value.substr(0, 1))} checked={splitMe == "Y"}/>
                                </div>
                                <div className="selection-radio">
                                  <label for="bgmusic">No</label>
                                  <input type="radio" name="bgmusic" value="No" onChange={(e) => setSplitme(e.target.value.substr(0, 1))} checked={splitMe == "N"}/>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div id={"translate-button" + (translating ? "-disabled" : "")} style={{ opacity: translating ? 0.2 : 1 }} onClick={() => {
                            if (!translating && !trim.loading) {
                              startTranslate()
                            }
                          }}>Translate</div>

                          <div className="errormsg">{errorMsg}</div>
                        </div>
                      </div>
                      <div id="video-container">
                        {prod ? 
                          !uploadedVideo.loading ? 
                            <div id="video-container-holder">
                              <video preload="auto" controls>
                                <source src={routeUrl + "/flask/" + uploadedVideo.name} type="video/mp4"/>
                              </video>
                            </div>
                            :
                            <div id="video-placeholder">
                              <div id="loading-icon" style={{ height: 100, width: 100 }}>
                                <AiOutlineLoading3Quarters style={{ color: 'lightgrey', height: '100%', width: '100%' }}/>
                              </div>
                            </div>
                          :
                          <div id="video-placeholder">
                            <MdOndemandVideo style={{ color: 'lightgrey', height: 100, width: 100 }}/>
                          </div>
                        }

                        <div id="control-section">
                          {translating ? 
                            <div id="progress-status">
                              {!errorMsg ? 
                                <>
                                  <div className="row">
                                    <div id="status-header-row">
                                      <div id="row-header">Working in progress. Please wait a moment</div>
                                      <div className="column">
                                        <div id="translating-loading">
                                          <AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <br/>

                                  {trimming ? 
                                    <div id="trimming-header">Trimming video</div>
                                    :
                                    <div id="translating-header">
                                      {translatingSteps[translatingIndex]}
                                    </div>
                                  }
                                
                                  {detectedSourcelang && (
                                    <>
                                      <br/>
                                      {detectedSourcelang && <div>Detected source language: {detectedSourcelang}</div>}
                                    </>
                                  )}
                                  <br/>
                                  <div style={{ fontSize: 15 }}>You will receive an email when your video is translated</div>
                                </>
                                :
                                <div className="errormsg">{errorMsg}</div>
                              }
                            </div>
                            :
                            <>
                              {videoCutted && <div id="video-cutted-header">Your video was over 20 minutes so now it is automatically trimmed to only 20 minutes</div>}
                            
                              <div id="bottom-options">
                                <div id="video-trim-container" style={{ opacity: trim.loading ? 0.5 : 1 }}>
                                  <div id="video-trim-actions">
                                    <div className="video-trim-action">
                                      <div className="video-trim-left">
                                        <div className="video-trim-left-header">Start point</div>
                                        <div className="video-trim-left-mini-header">(in seconds)</div>
                                        <div className="video-trim-control-container">
                                          <input 
                                            className="video-trim-left-input"
                                            disabled={trim.loading} 
                                            onChange={(e) => setTrim({ ...trim, begin: e.target.value, errorMsg: "" })} 
                                            placeholder="0" 
                                            value={trim.begin}
                                          />
                                          <div className="video-trim-right">
                                            <div className="trim-control-container">
                                              <div className="trim-control" onClick={(e) => {
                                                if (!trim.loading) {
                                                  let { begin } = trim

                                                  begin++

                                                  setTrim({ ...trim, begin, errorMsg: "" })
                                                }
                                              }}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                              <div className="trim-control" onClick={(e) => {
                                                if (!trim.loading) {
                                                  let { begin } = trim

                                                  begin--

                                                  setTrim({ ...trim, begin, errorMsg: "" })
                                                }
                                              }}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="video-trim-action">
                                      <div className="video-trim-left">
                                        <div className="video-trim-left-header">End point</div>
                                        <div className="video-trim-left-mini-header">(in seconds)</div>
                                        <div className="video-trim-control-container">
                                          <input 
                                            className="video-trim-left-input"
                                            disabled={trim.loading} 
                                            onChange={(e) => setTrim({ ...trim, end: e.target.value, errorMsg: "" })} 
                                            placeholder="0" 
                                            value={trim.end}
                                          />
                                          <div className="video-trim-right">
                                            <div className="trim-control-container">
                                              <div className="trim-control" onClick={() => {
                                                if (!trim.loading) {
                                                  let { end } = trim

                                                  end++

                                                  setTrim({ ...trim, end, errorMsg: "" })
                                                }
                                              }}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                              <div className="trim-control" onClick={() => {
                                                if (!trim.loading) {
                                                  let { end } = trim

                                                  if (end > 0) {
                                                    end--
                                                  }

                                                  setTrim({ ...trim, end, errorMsg: "" })
                                                }
                                              }}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div className="errormsg" style={{ marginVertical: 10 }}>{trim.errorMsg}</div>

                                  {trim.loading && (
                                    <div id="loading-icon" style={{ height: 28, width: 28 }}>
                                      <AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/>
                                    </div>
                                  )}
                                </div>

                                {(!translating && !trim.loading) && (
                                  <div id="video-actions-column">
                                    <div id="video-actions">
                                      <div className="video-action" onClick={() => {
                                        setUploadedvideo('')
                                        setTrim({ ...trim, errorMsg: '' })
                                        setErrormsg('')
                                      }}>
                                        <div className="column"><div className="video-action-header">Use a different video</div></div>
                                        <div className="column">
                                          <div className="video-action-icon">
                                            <IoCloudUploadOutline style={{ height: '100%', width: '100%' }}/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          }
        </div>

        <Footer/>
      </div>

      {(showUndone || editTlang.show) && (
        <div id="hidden" onClick={() => {
          setEdittlang({ ...editTlang, openLang: false })
        }}>
          {showUndone && (
            <div id="undone-box">
              <div id="undone-header">Final video is still translating</div>
            </div>
          )}

          {editTlang.show && (
            <div id="edit-target-langs">
              <div id="edit-target-langs-header">Add/Remove your target language(s)</div>

              <div id="edit-target-langs-list-header">Select up to 5 of your preferred languages</div>

              <div id="edit-target-langs-list">
                <div id="edit-target-langs-container">
                  <div id="edit-target-langs-button" onClick={(e) => {
                    setEdittlang({ ...editTlang, openLang: !editTlang.openLang })

                    e.stopPropagation()
                  }}>
                    <div className="select-tarlangs-header-header">Add a language</div>
                    <div className="select-tarlangs-arrow"><FaCaretDown style={{ height: '100%', width: '100%' }}/></div>
                  </div>

                  {editTlang.openLang === true && (
                    <div className="select-tarlangs-items" onClick={(e) => e.stopPropagation()}>
                      <input id="search-input" placeholder="Search language" maxLength="100" onChange={(e) => searchTheTargetLanguage(e.target.value)}/>

                      <div id="edit-target-langs-items-list">
                        {editTlang.allLangs.map((info, index) => (
                          <div 
                            className="select-tarlangs-item" 
                            key={"select-target-lang-" + index}
                            onClick={() => {
                              const newSelectedlangs = [...editTlang.selectedLangs]

                              if (newSelectedlangs.length < 5) {
                                newSelectedlangs.push(info)
                              }

                              setEdittlang({ ...editTlang, openLang: false, selectedLangs: newSelectedlangs })
                            }}
                          >
                            <div className="column"><div className="select-tarlangs-item-point"/></div>
                            <div className="column"><div className="select-tarlangs-item-header">{info.name}</div></div>

                            {JSON.stringify(editTlang.selectedLangs).includes(JSON.stringify(info)) && (
                              <div className="column">
                                <div className="select-tarlangs-item-indicator" onClick={e => {
                                  const newSelectedlangs = [...editTlang.selectedLangs]
                                  
                                  newSelectedlangs.splice(newSelectedlangs.indexOf(info), 1)

                                  setEdittlang({ ...editTlang, selectedLangs: newSelectedlangs })

                                  e.stopPropagation()
                                }}>
                                  <IoIosClose style={{ height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    
                    </div>
                  )}
                </div>
              </div>

              <div id="selected-langs-header">{editTlang.selectedLangs.length} Selected Target Language(s)</div>
              <div id="selected-langs">
                {editTlang.selectedLangs.map((lang, index) => (
                  <div className="selected-lang" key={"edit-selected-lang-" + index}>
                    <div className="selected-lang-header">{lang.name}</div>
                    <div className="column">
                      <div className="selected-lang-delete" onClick={(e) => {
                        const newSelectedlangs = [...editTlang.selectedLangs]

                        newSelectedlangs.splice(index, 1)

                        setEdittlang({ ...editTlang, selectedLangs: newSelectedlangs })

                        e.stopPropagation()
                      }}>
                        <IoIosClose style={{ height: '100%', width: '100%' }}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="errormsg">{errorMsg}</div>

              <div id="edit-target-langs-save" onClick={() => saveTheTargetLanguages()}>Finish</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
