const route = process.env.REACT_APP_URL + "/flask/user/"

export const login = data => {
  return fetch(route + "login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const thirdPartyLogin = data => {
  return fetch(route + "third_party_login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const resetPassword = data => {
  return fetch(route + "reset_password", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const register = data => {
  return fetch(route + "register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const thirdPartyContinue = data => {
  return fetch(route + "third_party_continue", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const setup = data => {
  return fetch(route + "setup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getUserInfo = data => {
  return fetch(route + "get_user_info", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const verifyUser = data => {
  return fetch(route + "verify_user", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const changePassword = data => {
  return fetch(route + "change_password", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  }) 
}
