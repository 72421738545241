import "./header.scss";

export default function Header({ redirect }) {
  return (
    <div id="header">
      <div className="column">
        <div id="header-title" onClick={() => window.location = redirect}>Video<span>Dub AI</span></div>
      </div>
    </div>
  )
}
