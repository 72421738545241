import "./changepassword.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { changePassword } from "../../apis/user";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function Changepassword() {
  const { id } = useParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmpassword] = useState('')
  const [errorMsg, setErrormsg] = useState('')

  const changeThePassword = () => {
    if (password.length >= 6) {
      if (password == confirmPassword) {
        const data = { id, password }

        changePassword(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              localStorage.setItem("id", res.id)

              window.location = "/"
            }
          })
          .catch((err) => {
            if (err.status == 400) {

            }
          })
      } else {
        setErrormsg("Password mismatch")
      }
    } else {
      setErrormsg("Password has to be 6 characters or more")
    }
  }

  return (
    <div id="changepassword">
      <Header redirect="/login"/>

      <div id="body">
        <div id="form" onKeyUp={e => {
          if (e.key == 'Enter') {
            changeThePassword()
          }
        }}>
          <div id="form-header">Change your password</div>

          <div id="inputs">
            <div className="input-container">
              <div className="input-header">Enter a new password:</div>
              <div className="input-value">
                <input type="password" maxLength="50" onChange={e => setPassword(e.target.value)} value={password}/>
              </div>
            </div>
            <div className="input-container">
              <div className="input-header">Confirm your new password:</div>
              <div className="input-value">
                <input type="password" onChange={e => setConfirmpassword(e.target.value)} value={confirmPassword}/>
              </div>
            </div>
          </div>

          <div id="errormsg">{errorMsg}</div>

          <div id="form-submit" onClick={() => changeThePassword()}>Change password</div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}
