import "./hasbackgroundmusic.scss";
import { useEffect, useState } from "react";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function Hasbackgroundmusic() {
  return (
    <div id="hasbackgroundmusic">
      <iframe src="https://forms.office.com/pages/responsepage.aspx?id=PFRswyLdfUyQIjw08yxK5WgNgzoedPFKq6RZ59vCe6hUODFVTjNIRFAwSFUwSUsyUUs4V1RONzFLNC4u&origin=lprLink"/>
    </div>
  )
}
