import "./playsource.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSourceVideo } from "../../apis/video"
import { AiOutlineLoading3Quarters } from "react-icons/ai";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

const routeUrl = process.env.REACT_APP_URL

export default function Playsource() {
  const { id } = useParams()
  const [videoLink, setVideolink] = useState('')

  const getTheSourceVideo = () => {
    const data = { videoId: id }

    getSourceVideo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setVideolink(res.videoLink)
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          
        }
      })
  }

  useEffect(() => {
    getTheSourceVideo()
  }, [])

  return (
    <div id="playsource" onContextMenu={e => {
      if (process.env.NODE_ENV != "development") {
        e.preventDefault()
      }
    }}>
      <Header/>

      <div id="container">
        {videoLink ? 
          <div id="playsource-video">
            <div id="playsource-header">The source video</div>
            <div id="holder-row" style={{ height: '80%' }}>
              <div id="holder">
                <video preload="auto" controls controlsList="nodownload" onContextMenu={e => e.preventDefault()}>
                  <source src={routeUrl + "/flask/" + videoLink} type="video/mp4"/>
                </video>
              </div>
            </div>
          </div>
          :
          <div id="loading">
            <div>
              <div id="loading-header">Loading source video</div>
              <div id="loading-icon">
                <AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/>
              </div>
            </div>
          </div>
        }
      </div>
      
      <Footer/>
    </div>
  )
}