import "./setup.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { setup, verifyUser } from "../../apis/user"
import { getAllTargetLanguages } from "../../apis/video";
import { FaCaretDown } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function Setup() {
  const [userId, setUserid] = useState(null)
  const { id } = useParams()
  const [username, setUsername] = useState('')

  const [targetLangs, setTargetlangs] = useState([])
  const [langsList, setLangslist] = useState([])
  const [openTlang, setOpentlang] = useState(false)
  const [searchLangs, setSearchlangs] = useState([])
  const [selectedTLangs, setSelectedtlangs] = useState([])

  const [errorMsg, setErrormsg] = useState('')

  const verifyTheUser = id => {
    verifyUser({ userId: id })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.msg == "granted") {
            getAllTheTargetLanguages()
          } else {
            window.location = "/"
          }
        }
      })
      .catch((err) => {
        if (err.status == 400) {

        }
      })
  }
  const getAllTheTargetLanguages = () => {
    getAllTargetLanguages()
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        
        throw res
      })
      .then((res) => {
        if (res) {
          setTargetlangs(res.tLangs)
          setLangslist(res.tLangs)
        }
      })
      .catch((err) => {
        if (err.status == 400) {

        }
      })
  }
  const searchLanguages = lang => {
    const searches = []

    if (lang) {
      targetLangs.forEach(function (info) {
        if (info.name.substr(0, lang.length).toLowerCase() === lang.toLowerCase()) {
          searches.push(info)
        }
      })

      setLangslist(searches)
    } else {
      setLangslist(targetLangs)
    }
  }
  const theSetup = () => {
    if (username && selectedTLangs.length > 0) {
      const data = { userId, username, languages: JSON.stringify(selectedTLangs) }

      setup(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res.id) {
            localStorage.setItem("id", res.id.toString())
            window.location = "/"
          }
        })
        .catch((err) => {
          if (err.status == 400) {

          }
        })
    } else {
      if (!username) {
        setErrormsg("Please enter a username")
      } else {
        setErrormsg("Please select at least 3 of your preferred languages")
      }
    }
  }

  useEffect(() => {
    if (id) {
      localStorage.setItem("id", id.toString())

      window.history.replaceState(null, null, "/setup")

      setUserid(id)
    } else {
      setUserid(parseInt(localStorage.getItem("id")))
    }
  }, [])

  useEffect(() => {
    if (userId) {
      verifyTheUser(userId)
    }
  }, [userId])

  return (
    <div id="setup" onClick={() => {
      if (openTlang) {
        getAllTheTargetLanguages()
      }

      setOpentlang(false)
    }}>
      <Header redirect={"/setup"}/>

      <div id="user-actions-container">
        <div id="user-actions-column">
          <div id="user-actions">
            <div className="user-action" onClick={() => {
              localStorage.clear()

              window.location = "/"
            }}>
              <div className="user-action-header">Log-Out</div>
            </div>
          </div>
        </div>
      </div>

      <div id="body">
        <div id="form" onKeyUp={(e) => {
          if (e.key == "Enter") {
            theSetup()
          }
        }}>
          <div id="form-header">Setup</div>

          <div id="note">Please provide a few details about yourself</div>

          <div id="inputs">
            <div className="input-container">
              <div className="input-header">Username:</div>
              <div className="input-value">
                <input type="text" maxLength="50" onChange={e => {
                  setUsername(e.target.value)
                  setErrormsg("")
                }} value={username}/>
              </div>
            </div>
            <div className="input-container">
              <div id="select-tarlangs">
                <div className="input-header">Select up to 5 of your preferred target languages</div>
                <div id="select-tarlangs-container">
                  <div id="select-tarlangs-button" onClick={(e) => {
                    setOpentlang(!openTlang)

                    e.stopPropagation()
                  }}>
                    <div className="select-tarlangs-header-header">Add a language</div>
                    <div className="select-tarlangs-arrow"><FaCaretDown style={{ height: '100%', width: '100%' }}/></div>
                  </div>

                  {openTlang == true && (
                    <div className="select-tarlangs-items" onClick={(e) => e.stopPropagation()}>
                      <input id="search-input" placeholder="Search language" maxLength="100" onChange={(e) => searchLanguages(e.target.value)}/>

                      <div id="select-tarlangs-items-list">
                        {langsList.map((info, index) => (
                          <div 
                            className={"select-tarlangs-item-" + (
                              JSON.stringify(selectedTLangs).includes(JSON.stringify(info)) ? "selected" : "unselect"
                            )}
                            key={"tarlang-" + index}
                            onClick={() => {
                              const newSelectedtlangs = [...selectedTLangs]

                              if (newSelectedtlangs.length < 5) {
                                if (!JSON.stringify(selectedTLangs).includes(JSON.stringify(info))) {
                                  newSelectedtlangs.push(info)

                                  getAllTheTargetLanguages()
                                  setSelectedtlangs(newSelectedtlangs)
                                  setOpentlang(false)
                                }
                              } else {
                                setErrormsg("5 selected is maximum")
                              }
                            }}
                          >
                            <div className="column"><div className="select-tarlangs-item-point"/></div>
                            <div className="column"><div className="select-tarlangs-item-header">{info.name}</div></div>

                            {JSON.stringify(selectedTLangs).includes(JSON.stringify(info)) && (
                              <div className="column">
                                <div className="select-tarlangs-item-indicator" onClick={e => {
                                  const newSelectedtlangs = [...selectedTLangs]
                                  
                                  newSelectedtlangs.splice(newSelectedtlangs.indexOf(info), 1)

                                  if (newSelectedtlangs.length < 5) {
                                    setErrormsg("")
                                  }

                                  setSelectedtlangs(newSelectedtlangs)

                                  e.stopPropagation()
                                }}>
                                  <IoIosClose style={{ height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    
                    </div>
                  )}
                </div>
              </div>
              <div id="selected-langs">
                {selectedTLangs.map((lang, index) => (
                  <div className="selected-lang" key={"selected-lang-" + index}>
                    <div className="selected-lang-header">{lang.name}</div>
                    <div className="column">
                      <div className="selected-lang-delete" onClick={() => {
                        const newSelectedtlangs = [...selectedTLangs]

                        newSelectedtlangs.splice(index, 1)

                        setSelectedtlangs(newSelectedtlangs)
                      }}>
                        <IoIosClose style={{ height: '100%', width: '100%' }}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div id="errormsg">{errorMsg}</div>

          <div id="form-submit" onClick={() => theSetup()}>Finish</div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}
