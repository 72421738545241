import "./playtranslated.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTranslatedVideo, updateNumPlay, shareVideo } from "../../apis/video"
import { AiOutlineLoading3Quarters } from "react-icons/ai";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

const routeUrl = process.env.REACT_APP_URL

export default function Playtranslated() {
  const { id } = useParams()
  const [videoLink, setVideolink] = useState('')
  const [copied, setCopied] = useState(false)
  const [downloadLink, setDownloadlink] = useState('')

  const getTheTranslatedVideo = () => {
    const data = { videoId: id }

    getTranslatedVideo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setVideolink(res.videoLink)
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          
        }
      })
  }
  const updateTheNumPlay = () => {
    const data = { videoId: id, type: "after_email" }

    updateNumPlay(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {

        }
      })
      .catch((err) => {

      })
  }
  const shareTheVideo = () => {
    const data = { videoId: id, type: "email" }

    shareVideo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          let value = document.getElementById("bottom-section-header-value").innerHTML

          if (value) {
            navigator.clipboard.writeText(value)

            setCopied(true)

            setTimeout(function () {
              setCopied(false)
            }, 500)
          }
        }
      })
      .catch((err) => {

      })
  }

  useEffect(() => {
    const video = document.getElementById('feedback-iframe');

    // Event listener for when the video data has been loaded
    video.addEventListener('load', () => {
      setTimeout(function () {
        getTheTranslatedVideo()
      }, 1000)
    });
  }, [])

  useEffect(() => {
    if (downloadLink) {
      setTimeout(function () {
        document.getElementById("download").click()
      }, 1000)
    }
  }, [downloadLink])

  return (
    <div id="playtranslated" onContextMenu={e => {
      if (process.env.NODE_ENV != "development") {
        e.preventDefault()
      }
    }}>
      <Header/>

      <div id="container">
        {videoLink ? 
          <div id="playtranslated-video">
            <div id="playtranslated-header">Enjoy your translated video</div>
            <div id="holder-row">
              <div id="holder">
                <video preload="auto" controls onPlay={() => updateTheNumPlay()} controlsList="nodownload" onContextMenu={e => e.preventDefault()}>
                  <source src={routeUrl + "/flask/" + videoLink} type="video/mp4"/>
                </video>
              </div>
            </div>
            <div id="bottom-section-row">
              <div id="bottom-section">
                <div id="bottom-section-container">
                  <div id="bottom-section-header">
                    <div id="bottom-section-header-value">{process.env.REACT_APP_SHARE_URL}/{id}</div>
                  </div>
                  <div id="bottom-section-button" onClick={() => shareTheVideo()}>
                    Share
                    {copied && <><br/>(copied)</>}
                  </div>
                </div>
                <div className="bottom-section-action" onClick={() => window.location = "/playsource/" + id}>Play source</div>
                {downloadLink && <a id="download" href={downloadLink} download="doubleclick.zip"></a>}
                <div className="bottom-section-action" onClick={() => {
                  if (localStorage.getItem("id")) {
                    window.location = "/"
                  } else {
                    window.location = "/login"
                  }
                }}>Translate My Video</div>
              </div>
            </div>
          </div>
          :
          <div id="loading">
            <div>
              <div id="loading-header">Loading translated video</div>
              <div id="loading-icon">
                <AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/>
              </div>
            </div>
          </div>
        }

        <div id="feedback">
          <iframe id="feedback-iframe" height="100%" src="https://forms.office.com/r/Cwtvi5McA5" frameborder="0"/>
        </div>
      </div>
      
      <Footer/>
    </div>
  )
}