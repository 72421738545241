import "./App.scss";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import Login from "./pages/login"
import Resetpassword from "./pages/resetpassword"
import Changepassword from "./pages/changepassword"
import Register from "./pages/register"
import Setup from "./pages/setup"
import Verify from "./pages/verify"
import Main from "./pages/main"
import Landing from "./pages/landing"

import Playtranslated from "./pages/playtranslated"
import Playsource from "./pages/playsource"
import Email from "./templates/emails/email"

import Form from "./research/hasbackgroundmusic"

// Spanish
import EsRegister from "./pages/es/register"
import EsLanding from "./pages/es/landing"
import EsVerify from "./pages/es/verify"


export default function App() {
  const [userId, setUserid] = useState(null)

  useEffect(() => {
    const id = localStorage.getItem("id")

    if (id) {
      setUserid(id)
    }
  }, [])

  return (
    <GoogleOAuthProvider 
      clientId={process.env.REACT_APP_GOOGLE_CLIENT}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={userId ? <Main/> : <Landing/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/resetpassword" element={<Resetpassword/>}/>
          <Route path="/changepassword/:id" element={<Changepassword/>}/>
          <Route path="/register" element={<Register/>}/>

          <Route path="/setup" element={<Setup/>}/>
          <Route path="/setup/:id" element={<Setup/>}/>

          <Route path="/verify" element={<Verify/>}/>
          <Route path="/main" element={userId ? <Main/> : <Login/>}/>
          <Route path="/landing" element={<Landing/>}/>

          <Route path="/playtranslated/:id" element={<Playtranslated/>}/>
          <Route path="/playsource/:id" element={<Playsource/>}/>

          <Route path="/email" element={<Email/>}/>

          <Route path="/es" element={userId ? <Main/> : <EsLanding/>}/>
          <Route path="/es/register" element={<EsRegister/>}/>
          <Route path="/es/landing" element={<EsLanding/>}/>
          <Route path="/es/verify" element={<EsVerify/>}/>

          <Route path="/form" element={<Form/>}/>
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  )
}
