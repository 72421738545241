import "./landing.scss";
import { useEffect, useState } from "react";
import { getSampleTargetLanguages } from "../../apis/video";
import { FaPhotoVideo, FaCaretDown } from "react-icons/fa";
import { AiOutlineLogout, AiOutlineLoading3Quarters } from "react-icons/ai";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

const routeUrl = process.env.REACT_APP_URL

export default function Landing() {
  const [userId, setUserid] = useState(null)

  const [targetLangs, setTargetlangs] = useState([])
  const [langsList, setLangslist] = useState([])
  const [openTlang, setOpentlang] = useState(false)
  const [searchLangs, setSearchlangs] = useState([])
  const [selectedTLang, setSelectedtlang] = useState({ code: '', name: '' })

  const [loading, setLoading] = useState(false)

  const getTheSampleTargetLanguages = () => {
    getSampleTargetLanguages()
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        
        throw res
      })
      .then((res) => {
        if (res) {
          setTargetlangs(res.tLangs)
          setLangslist(res.tLangs)
          setSelectedtlang(res.defaultSelected)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const searchLanguages = lang => {
    const searches = []

    if (lang) {
      targetLangs.forEach(function (info) {
        if (info.name.substr(0, lang.length).toLowerCase() === lang.toLowerCase()) {
          searches.push(info)
        }
      })

      setLangslist(searches)
    } else {
      setLangslist(targetLangs)
    }
  }

  useEffect(() => {
    const id = localStorage.getItem("id")

    if (id) {
      setUserid(id)
    }

    getTheSampleTargetLanguages()
  }, [])

  useEffect(() => {
    if (selectedTLang.code) {
      setOpentlang(false)

      setLoading(true)

      setTimeout(function () {
        setLoading(false)
      }, 500)
    }
  }, [selectedTLang.code])

  return (
    <div id="landing" onClick={() => {
      // close all dropdown containers
      setOpentlang(false)
    }}>
      <Header redirect="/"/>

      <div id="user-actions-container">
        <div id="user-actions-column">
          <div id="user-actions">
            <div className="user-action" onClick={() => {
              localStorage.clear()

              window.location = "/login"
            }}>
              <div className="user-action-header">Log-In</div>
            </div>
          </div>
        </div>
      </div>

      {userId && (
        <div id="user-actions">
          <div className="user-action" onClick={() => {
            localStorage.clear()

            window.location = "/login"
          }}>
            <div className="column"><div className="user-action-header">Log-Out</div></div>
            <div className="column"><div className="user-action-icon"><AiOutlineLogout style={{ height: '100%', width: '100%' }}/></div></div>
          </div>
        </div>
      )}

      <div id="intro">
        <div id="top-section">
          <div>
            <div id="header-title">Welcome to AI Video Translate</div>
            <div id="header-mini-title">
              Your FREE tool for enjoying videos in any foreign language
              <br/><br/>
              AI Video Translate instantly translates and adds voiceover to your video instantly into 65 languages,
              fully automatically. Upload or fetch a video from a Youtube Link, and you can watch it in your chosen 
              language in just a few minutes. Try it now!
            </div>
          </div>
        </div>
        
        <div id="bottom-section">
          <div id="samples-row">
            <div id="samples">
              <div className="sample">
                <div className="sample-header">English</div>
                <div className="sample-video">
                  <video preload="auto" controls>
                    <source src="/english.mp4" type="video/mp4"/>
                  </video>
                </div>
              </div>
              <div className="sample">
                <div className="select-tarlangs">
                  <div className="select-tarlangs-container">
                    <div className="select-tarlangs-button" onClick={(e) => {
                      setOpentlang(!openTlang)

                      e.stopPropagation()
                    }}>
                      <div className="select-tarlangs-header-header">{selectedTLang.name ? selectedTLang.name : "Select the language"}</div>
                      <div className="select-tarlangs-arrow"><FaCaretDown style={{ height: '100%', width: '100%' }}/></div>
                    </div>

                    {openTlang === true && (
                      <div className="select-tarlangs-items" onClick={(e) => e.stopPropagation()}>
                        <input id="search-input" placeholder="Search language" maxLength="100" onChange={(e) => searchLanguages(e.target.value)}/>

                        <div id="select-tarlangs-items-list">
                          {langsList.map((info, index) => (
                            <div 
                              className={"select-tarlangs-item" + (selectedTLang.name === info.name ? "-selected" : "")}
                              onClick={() => setSelectedtlang(info)}
                            >
                              <div className="column"><div className="select-tarlangs-item-point"/></div>
                              <div className="column"><div className="select-tarlangs-item-header">{info.name}</div></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="sample-video">
                  {loading ? 
                    <div id="loading-container">
                      <div id="loading"><AiOutlineLoading3Quarters style={{ height: '100%', width: '100%' }}/></div>
                    </div>
                    :
                    <video preload="auto" controls>
                      <source 
                        src={
                          selectedTLang.code ? 
                            routeUrl + "/flask/static/sample_videos/SampleVideo-" + selectedTLang.code + ".mp4"
                            :
                            "/english.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                  }
                </div>
              </div>
            </div>
          </div>

          <div id="translate-button-container">
            <div id="translate-button" onClick={() => window.location = "/login"}>Translate My Video</div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}