import "./login.scss";
import { useEffect, useState } from "react"
import {
  MsalProvider,
  useMsal,
  useIsAuthenticated
} from '@azure/msal-react';
import { msalInstance, loginRequest } from '../../ms-config';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { FaCheck } from "react-icons/fa";
import { login, thirdPartyContinue } from "../../apis/user"

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function Login() {
  const [showNormalauth, setShownormalauth] = useState(false)
  const [username, setUsername] = useState(process.env.NODE_ENV == "production" ? "" : "kevin@videolocalize.com")
  const [password, setPassword] = useState(process.env.NODE_ENV == "production" ? "" : "qqqqqq")

  const [remember, setRemember] = useState(false)

  const [errorMsg, setErrormsg] = useState('')

  const theLogin = () => {
    if (username && password) {
      const data = { username, password }

      login(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("id", res.id)

            if (res.username) {
              window.location = "/"
            } else { // unverified
              window.location = "/verify"
            }
          }
        })
        .catch((err) => {
          if (err.status == 400) {
            err.json().then(({ errorMsg }) => {
              setErrormsg(errorMsg)
            })
          }
        })
    } else {
      if (!username) {
        setErrormsg("Username is empty")
      } else {
        setErrormsg("Password is empty")
      }
    }
  }
  const theThirdPartyContinue = info => {
    const { email, id, type } = info
    const data = { email, id, type }

    thirdPartyContinue(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          localStorage.setItem("id", res.id)

          if (res.username) {
            window.location = "/"
          } else { // unverified
            window.location = "/setup/" + res.id
          }
        }
      })
      .catch((err) => {
        if (err.status == 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "emailTaken":
                setErrormsg("E-mail already taken. Please continue with e-mail instead")

                break;
            }
          })
        }
      })
  }

  const microsoftLogin = async() => {
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);

      msalInstance.setActiveAccount(loginResponse.account);
      const accessTokenResponse = await msalInstance.acquireTokenSilent(loginRequest);

      fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessTokenResponse.accessToken}`,
        },
      })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { userPrincipalName, id } = res

          theThirdPartyContinue({ email: userPrincipalName, id, type: "microsoft" })
        }
      })
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  const responseGoogle = async ({ code }) => {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT,
        client_secret: process.env.REACT_APP_GOOGLE_SECRET,
        code: code,
        grant_type: 'authorization_code',
        redirect_uri: process.env.REACT_APP_REDIRECT,
      }),
    });

    const data = await response.json();
    const accessToken = data.access_token;

    // Use access token to fetch user info
    const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const { sub, email } = await userInfoResponse.json();
    
    theThirdPartyContinue({ email, id: sub, type: "google" })
  }
  const googleLogin = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT,
    onSuccess: (res) => responseGoogle(res),
    flow: 'auth-code',
  })

  return (
    <div id="login">
      <Header redirect="/login"/>

      <div id="body">
        <div id="form" onKeyUp={e => {
          if (e.key == 'Enter') {
            theLogin()
          }
        }}>
          <div id="note" onClick={() => window.location = "/register"}>Don't have an account? Sign Up</div>

          <div id="form-header">Log In</div>

          {!showNormalauth && (
            <div id="logins-row">
              <div id="logins">
                <div className="thirdparty-login" onClick={() => googleLogin()}>
                  <div className="thirdparty-login-header">Continue with Google</div>
                </div>

                <MsalProvider instance={msalInstance}>
                  <div className="thirdparty-login" onClick={() => microsoftLogin()}>
                    <div className="thirdparty-login-header">Continue with Microsoft</div>
                  </div>
                </MsalProvider>

                <div id="thirdparty-header">Or</div>

                <div id="email-login" onClick={() => {
                  setShownormalauth(true)
                  setErrormsg('')
                }}>
                  <div id="email-login-header">Continue with E-mail</div>
                </div>
              </div>
            </div>
          )}

          {showNormalauth ? 
            <>
              <div id="instruction-header">Please fill out the following fields to login:</div>

              <div id="inputs">
                <div className="input-container">
                  <div className="input-header">Username or email:</div>
                  <div className="input-value">
                    <input type="email" maxLength="50" onChange={e => setUsername(e.target.value)} value={username}/>
                  </div>
                </div>
                
                <div className="input-container">
                  <div className="input-header">Password:</div>
                  <div className="input-value">
                    <input type="password" onChange={e => setPassword(e.target.value)} value={password}/>
                  </div>
                </div>
              </div>

              <div id="remember">
                <div id={"remember-check" + (remember ? "ed" : "")} onClick={() => setRemember(!remember)}>
                  {remember && <FaCheck style={{ color: 'white', height: '70%', width: '70%' }}/>}
                </div>
                <div className="column"><div id="remember-header">Remember Me</div></div>
              </div>

              <div id="resetpassword-button">If you forgot your password you can <a href="/resetpassword">reset it</a></div>

              <div id="errormsg">{errorMsg}</div>

              <div id="form-actions">
                <div className="form-action" style={{ marginRight: 5 }} onClick={() => {
                  setShownormalauth(false)
                  setErrormsg('')
                }}>Back</div>
                <div className="form-action" style={{ marginLeft: 5 }} onClick={() => theLogin()}>Login</div>
              </div>
            </>
            :
            <div id="errormsg">{errorMsg}</div>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}