import "./footer.scss";

export default function Footer() {
  return (
    <div id="footer-comp">
      <div id="footer-container">
        <div id="co-logo">
          <img src="/title.png"/>
        </div>
        <div id="co-header">@ {new Date().getFullYear()} by VideoLocalize</div>
      </div>
    </div>
  )
}
