import "./verify.scss"
import { IoMail } from "react-icons/io5";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function verify() {
  return (
    <div id="verify">
      <Header redirect="/verify"/>

      <div id="body">
        <div id="verify-user">
          <IoMail style={{ color: 'lightblue', height: '100%', width: '100%' }}/>
        </div>

        <div id="verify-header">
          Thank you for joining:) 
          <br/><br/>
          Please check your email to verify your account
        </div>
      </div>
        
      <Footer/>
    </div>
  )
}