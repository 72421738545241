import "./resetpassword.scss";
import { useEffect, useState } from "react";
import { resetPassword } from "../../apis/user"
import { MdEmail } from "react-icons/md";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function Resetpassword() {
  const [username, setUsername] = useState(process.env.NODE_ENV == "production" ? "" : "kevin@videolocalize.com")
  const [emailSent, setEmailsent] = useState('')
  const [errorMsg, setErrormsg] = useState('')

  const theResetPassword = () => {
    if (username) {
      const data = { username }

      resetPassword(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setEmailsent(res.email)
          }
        })
        .catch((err) => {
          if (err.status == 400) {
            err.json().then(({ errorMsg }) => {
              setErrormsg(errorMsg)
            })
          }
        })
    }
  }

  return (
    <div id="resetpassword">
      <Header redirect="/resetpassword"/>

      <div id="body">
        <div id="form">
          {!emailSent ? 
            <>
              <div id="form-header">Reset Password</div>

              <div id="note" onClick={() => window.location = "/register"}>Don't have an account? Sign Up</div>

              <div id="instruction-header">Please enter your e-mail to get the reset password link:</div>

              <div id="inputs">
                <div className="input-container">
                  <div className="input-header">Username or email:</div>
                  <div className="input-value">
                    <input type="email" maxLength="50" onChange={e => setUsername(e.target.value)} value={username} onKeyUp={(e) => {
                      if (e.key == "Enter") {
                        theResetPassword()
                      }
                    }}/>
                  </div>
                </div>
              </div>

              <div id="errormsg">{errorMsg}</div>

              <div id="form-submit" onClick={() => theResetPassword()}>Reset Password</div>
            </>
            :
            <>
              <div id="form-sent">
                <MdEmail style={{ color: 'black', height: '100%', width: '100%' }}/>
              </div>

              <div id="form-header" style={{ textAlign: 'center' }}>Ok, Link is sent to<br/>{emailSent}</div>
            </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}
