import { Configuration } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT,
      authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_MICROSOFT_TENANT,
      redirectUri: '/', // Update with your redirect URI
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const loginRequest = {
  scopes: ['openid', 'profile', 'user.read'] // Add other scopes as needed
};