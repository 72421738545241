import axios from "axios";

const route = process.env.REACT_APP_URL + "/flask/video/"

export const saveVideo = data => {
  return axios.post(route + "save_video", data, {
    headers: { "Content-Type": "application/json" }
  })
}

export const getSourceLanguages = () => {
  return fetch(route + "get_source_languages")
}

export const getAllTargetLanguages = data => {
  return fetch(route + "get_all_target_languages")
}

export const getTargetLanguages = data => {
  return fetch(route + "get_target_languages", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const saveTargetLanguages = data => {
  return fetch(route + "save_target_languages", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getSampleTargetLanguages = () => {
  return fetch(route + "get_sample_target_languages")
}

export const getTranslatedVideo = data => {
  return fetch(route + "get_translated_video", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getSourceVideo = data => {
  return fetch(route + "get_source_video", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const trimVideo = data => {
  return fetch(route + "trim_video", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const updateNumPlay = data => {
  return fetch(route + "update_numplays", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const shareVideo = data => {
  return fetch(route + "share_video", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const gatherClipInfos = data => {
  return fetch(route + "gather_clip_infos", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getVidClips = data => {
  return fetch(route + "get_vidclips", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

// research
export const getPlaylist = () => {
  return fetch(route + "get_playlist")
}
