import "./register.scss";
import { useEffect, useState } from "react";
import {
  MsalProvider,
  useMsal,
  useIsAuthenticated
} from '@azure/msal-react';
import { msalInstance, loginRequest } from '../../ms-config';
import { GoogleLogin, useGoogleLogin, GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import { register, thirdPartyContinue } from "../../apis/user"

// components
import Header from "../../components/header"
import Footer from "../../components/footer"

export default function Register() {
  const { instance } = useMsal();
  const [showNormalauth, setShownormalauth] = useState(false)
  const [email, setEmail] = useState(process.env.NODE_ENV == "production" ? "" : "kevin@videolocalize.com")
  const [password, setPassword] = useState(process.env.NODE_ENV == "production" ? "" : "qqqqqq")
  const [confirmPassword, setConfirmpassword] = useState(process.env.NODE_ENV == "production" ? "" : "qqqqqq")
  const [errorMsg, setErrormsg] = useState('')

  const theRegister = () => {
    if (email && password && confirmPassword) {
      const data = { email, password }
      
      register(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("id", res.id)

            window.location = "/verify"
          }
        })
        .catch((err) => {
          if (err.status == 400) {
            if (err.status == 400) {
              err.json().then(({ errorMsg }) => {
                setErrormsg(errorMsg)
              })
            }
          }
        })
    } else {
      if (!email) {
        setErrormsg("Please enter an e-mail")
      } else if (!password) {
        setErrormsg("Please enter a password")
      } else {
        setErrormsg("Please confirm your password")
      }
    }
  }
  const theThirdPartyContinue = info => {
    const { email, id, type } = info
    const data = { email, id, type }

    thirdPartyContinue(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          localStorage.setItem("id", res.id)

          if (res.username) {
            window.location = "/"
          } else { // unverified
            window.location = "/setup/" + res.id
          }
        }
      })
      .catch((err) => {
        if (err.status == 400) {
          err.json().then(({ errorMsg }) => {
            setErrormsg(errorMsg)
          })
        }
      })
  }

  const microsoftSignUp = async() => {
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);

      msalInstance.setActiveAccount(loginResponse.account);
      const accessTokenResponse = await msalInstance.acquireTokenSilent(loginRequest);

      fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessTokenResponse.accessToken}`,
        },
      })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { userPrincipalName, id } = res

          theThirdPartyContinue({ email: userPrincipalName, id, type: "microsoft" })
        }
      })
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  const responseGoogle = async ({ code }) => {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT,
        client_secret: process.env.REACT_APP_GOOGLE_SECRET,
        code: code,
        grant_type: 'authorization_code',
        redirect_uri: process.env.REACT_APP_REDIRECT,
      }),
    });

    const data = await response.json();
    const accessToken = data.access_token;

    // Use access token to fetch user info
    const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const { sub, email } = await userInfoResponse.json();
    
    theThirdPartyContinue({ email, id: sub, type: "google" })
  }
  const googleLogin = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT,
    onSuccess: (res) => responseGoogle(res),
    flow: 'auth-code',
  })
  
  return (
    <div id="register">
      <Header redirect="/register"/>

      <div id="body">
        <div id="form" onKeyUp={e => {
          if (e.key == 'Enter') {
            theRegister()
          }
        }}>
          <div id="note" onClick={() => window.location = "/login"}>Already have an account? Log In</div>

          <div id="form-header">Sign Up</div>

          {!showNormalauth && (
            <div id="signups-row">
              <div id="signups">
                <div className="thirdparty-signup" onClick={() => googleLogin()}>
                  <div className="thirdparty-signup-header">Continue with Google</div>
                </div>

                <MsalProvider instance={msalInstance}>
                  <div className="thirdparty-signup" onClick={() => microsoftSignUp()}>
                    <div className="thirdparty-signup-header">Continue with Microsoft</div>
                  </div>
                </MsalProvider>

                <div id="thirdparty-header">Or</div>

                <div id="email-login" onClick={() => setShownormalauth(true)}>
                  <div id="email-login-header">Continue with E-mail</div>
                </div>
              </div>
            </div>
          )}

          {showNormalauth ? 
            <>
              <div id="instruction-header">Please fill out the following fields to register:</div>

              <div id="inputs">
                <div className="input-container">
                  <div className="input-header">E-mail:</div>
                  <div className="input-value">
                    <input type="email" maxLength="50" onChange={e => setEmail(e.target.value)} value={email}/>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-header">Password:</div>
                  <div className="input-value">
                    <input type="password" onChange={e => setPassword(e.target.value)} value={password}/>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-header">Confirm your password:</div>
                  <div className="input-value">
                    <input type="password" onChange={e => setConfirmpassword(e.target.value)} value={confirmPassword}/>
                  </div>
                </div>
                
                <div id="errormsg">{errorMsg}</div>

                {showNormalauth ? 
                  <div id="form-actions">
                    <div className="form-action" style={{ marginRight: 5 }} onClick={() => {
                      setShownormalauth(false)
                      setErrormsg('')
                    }}>Back</div>
                    <div className="form-action" style={{ marginLeft: 5 }} onClick={() => theRegister()}>Register</div>
                  </div>
                  :
                  <div className="form-action" onClick={() => setShownormalauth(true)}>Next</div>
                }
              </div>

              <div id="resetpassword-button">If you already have an account, <a href="/login">log in</a></div>
            </>
            :
            <div id="errormsg">{errorMsg}</div>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}
