import "./email.scss";
import { MdMarkEmailRead } from "react-icons/md";

export default function Email() {
  return (
    <div id="email">
      <div id="container">
        <div id="logo">
          <img src="/title.png"/>
        </div>

        <div id="verify-icon"><MdMarkEmailRead style={{ color: 'lightblue', height: '100%', width: '100%' }}/></div>

        <div id="header">Hello username</div>
        <div id="mini-header">
          Thank you, your email has been verified. Your account is now activated.
          <br/>
          Please use the link below to login to your account
        </div>

        <div id="login-button">LOGIN TO YOUR ACCOUNT</div>
      </div>
    </div>
  )
}
