import "./register.scss";
import { useEffect, useState } from "react";
import { register } from "../../../apis/user"

// components
import Header from "../../../components/header"
import Footer from "../../../components/footer"

export default function Register() {
  const [email, setEmail] = useState(process.env.NODE_ENV == "production" ? "" : "kevin@videolocalize.com")
  const [password, setPassword] = useState(process.env.NODE_ENV == "production" ? "" : "qqqqqq")
  const [confirmPassword, setConfirmpassword] = useState(process.env.NODE_ENV == "production" ? "" : "qqqqqq")
  const [errorMsg, setErrormsg] = useState('')

  const theRegister = () => {
    if (email && password && confirmPassword) {
      const data = { email, password, language: "spanish" }
      
      register(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("id", res.id)

            window.location = "/es/verify"
          }
        })
    } else {
      if (!email) {
        setErrormsg("Please enter an e-mail")
      } else if (!password) {
        setErrormsg("Please enter a password")
      } else {
        setErrormsg("Please confirm your password")
      }
    }
  }

  return (
    <div id="register">
      <Header redirect="/register"/>

      <div id="body">
        <div id="form" onKeyUp={e => {
          if (e.key == 'Enter') {
            theRegister()
          }
        }}>
          <div id="form-header">Registrarse</div>

          <div id="note" onClick={() => window.location = "/login"}>¿Ya tienes una cuenta? Inicia sesión</div>

          <div id="instruction-header">Rellena los siguientes campos para registrarte::</div>

          <div id="inputs">
            <div className="input-container">
              <div className="input-header">Correo electrónico:</div>
              <div className="input-value">
                <input type="email" maxLength="50" onChange={e => setEmail(e.target.value)} value={email}/>
              </div>
            </div>
            <div className="input-container">
              <div className="input-header">Contraseña:</div>
              <div className="input-value">
                <input type="password" onChange={e => setPassword(e.target.value)} value={password}/>
              </div>
            </div>
            <div className="input-container">
              <div className="input-header">Confirma tu contraseña:</div>
              <div className="input-value">
                <input type="password" onChange={e => setConfirmpassword(e.target.value)} value={confirmPassword}/>
              </div>
            </div>
          </div>

          <div id="resetpassword-button">Si ya tienes una cuenta, <a href="/login">inicia sesión</a></div>

          <div id="errormsg">{errorMsg}</div>

          <div id="form-submit" onClick={() => theRegister()}>Registrarse</div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}
